<template>
  <div>
    <a-button @click="visible = true">录入</a-button>

    <a-modal
      :visible="visible"
      title="录入新票"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item
          label="原发票号码"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            v-decorator="[
              'invoiceCode',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>
        <a-form-item
          label="原发票金额(元)"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input-number
            :formatter="$inputNumberFormat"
            style="width: 100%"
            v-decorator="[
              'totalPriceLowercase',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          label="原开票单位"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            v-decorator="[
              'salesUnitName',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          label="原开票内容"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-input
            v-decorator="[
              'oldInvoiceContenet',
              {
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-divider />

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
    };
  },

  methods: {
    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit("change", values);
          this.cancel();
        }
      });
    },
  },
};
</script>