import request from '../request'
import download from '../download'

export function fetchList(data) {
    return request({
        url: '/project-service/contract/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/project-service/contract/add',
        method: 'POST',
        data
    })
}

export function fetchDetail(params) {
    return request({
        url: `/project-service/contract/query/${params}`,
    })
}

export function edit(data) {
    return request({
        url: '/project-service/contract/update',
        method: 'POST',
        data
    })
}

export function remove(data) {
    return request({
        url: '/project-service/contract/del',
        method: 'POST',
        data
    })
}

// 修改合同的生产负责人
export function updateMaster(data) {
    return request({
        url: '/project-service/contract/updateMaster',
        method: 'post',
        data,
    })
}

// 下载金慧的文件
export function downloadFile(data) {
    return download({
        url: '/common-service/file/jinHuiFile',
        method: 'post',
        data,
    })
}