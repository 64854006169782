<template>
  <div class="draft-wrapper">
    <a-dropdown>
      <div class="draft">
        <a-icon type="profile" class="icon" />
        <div>草稿箱</div>
      </div>
      <div class="list" slot="overlay">
        <div v-if="filteredList.length > 0">
          <div v-for="item in filteredList" :key="item.title" class="item">
            <span class="title" @click="select(item)">
              {{ item.title }}
            </span>

            <a href="#" class="danger" @click.prevent="removeItem(item)">
              <a-icon type="close" />
            </a>
          </div>
        </div>

        <a-empty v-else />
      </div>
    </a-dropdown>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState("draft", ["list"]),

    ...mapGetters("auth", ["getCurrentRoute"]),

    draftType() {
      let type = "";
      const routeObj = this.getCurrentRoute(this.$route.path);
      if (routeObj) {
        type = routeObj.name;
      }
      return type;
    },

    filteredList() {
      if (this.draftType) {
        return this.list.filter((item) => item.type === this.draftType);
      } else {
        return [];
      }
    },
  },

  mounted() {
    this.getDrafts();
  },

  methods: {
    ...mapActions("draft", ["getDrafts", "deleteDraft"]),

    removeItem(item) {
      const that = this;
      this.$confirm({
        title: "是否删除？",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          that.deleteDraft(item);
        },
      });
    },

    select(item) {
      const that = this;
      this.$confirm({
        title: "使用此草稿会覆盖页面数据，是否继续？",
        okText: "是",
        cancelText: "否",
        onOk() {
          that.$emit("select", item);
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.draft-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.draft {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1890ff;
  cursor: pointer;
  .icon {
    font-size: 22px;
    color: #1890ff;
    margin-bottom: 4px;
  }
}

.list {
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px;

  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    .title {
      display: inline-block;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 8px;
      line-height: 1.6em;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>
