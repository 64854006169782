var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{on:{"click":function($event){_vm.visible = true}}},[_vm._v("录入")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"录入新票","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"原发票号码","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'invoiceCode',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'invoiceCode',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"原发票金额(元)","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'totalPriceLowercase',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'totalPriceLowercase',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',{attrs:{"label":"原开票单位","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'salesUnitName',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'salesUnitName',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"原开票内容","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'oldInvoiceContenet',
            {
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'oldInvoiceContenet',\n            {\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-divider'),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }