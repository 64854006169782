<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <Draft @select="onSelectDraft" />
      </template>
    </Pane>

    <a-modal :visible="draftVisible" title="保存草稿" @ok="saveDraft" @cancel="cancelDraft">
      <div style="padding: 8px 0">
        <a-input placeholder="请输入草稿标题" v-model="draftTitle" />
      </div>
    </a-modal>

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <div class="title" style="margin-top: 0">基本信息</div>
        <div class="wrapper">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="申请部门">
                <a-button block style="text-align: left">{{ department.deptUniqueName }}</a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="申请人">
                <a-button block style="text-align: left">{{ user.userName }}</a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="生产负责人部门">
                <a-select
                  show-search
                  :filter-option="$selectFilterOption"
                  v-decorator="[
                                    'liquidatorDept',
                                    { rules: [{ required: true, message: '请选择！' }] },
                                  ]"
                  @change="onDeptChange"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.uniqueName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item>
                <span class="required" slot="label">生产负责人</span>
                <EmployeeSelector
                  @change="(arr) => {
                                    if (arr.length) {
                                      selectedEmployee = arr[0];
                                    } else {
                                      selectedEmployee = {};
                                    }
                                  }
                                  "
                  :value="selectedEmployee.name ? [selectedEmployee] : []"
                >
                  <a-button block style="text-align: left">{{ selectedEmployee.name }}</a-button>
                </EmployeeSelector>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="所属区域">
                <a-cascader
                  placeholder
                  changeOnSelect
                  v-decorator="['area']"
                  :options="areaList"
                  :fieldNames="{
                                    label: 'areaName',
                                    value: 'id',
                                    children: 'children',
                                  }"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="区域负责人">
                <EmployeeSelector
                  @change="(arr) => {
                                    if (arr.length) {
                                      selectedAreaMaster = arr[0];
                                    } else {
                                      selectedAreaMaster = {};
                                    }
                                  }
                                  "
                  :value="selectedAreaMaster.name ? [selectedAreaMaster] : []"
                >
                  <a-button block style="text-align: left">{{ selectedAreaMaster.name }}</a-button>
                </EmployeeSelector>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="申请类型">
                <a-select
                  v-decorator="[
                                    'applyType',
                                    { rules: [{ required: true, message: '请选择！' }] },
                                  ]"
                >
                  <a-select-option
                    v-for="item in applyTypeList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <template
          v-if="form.getFieldValue('applyType') === 'change' ||
                  form.getFieldValue('applyType') === 'refund'
                  "
        >
          <div class="title">退换票信息</div>
          <div class="wrapper">
            <a-row>
              <a-col :lg="24" :md="24" :sm="24">
                <a-form-item label="退换票原因" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                  <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['reason']" />
                </a-form-item>
              </a-col>

              <a-col :lg="24" :md="24" :sm="24">
                <a-form-item :wrapper-col="{ span: 18, offset: 4 }">
                  <div class="right">
                    <a-space>
                      <AddOriginalInvoice @change="(values) => selectedInvoiceList.push(values)" />

                      <InvoiceSelector
                        :single="false"
                        buttonType="primary"
                        @getValue="(obj) => {
                                                selectedInvoiceList.push({
                                                  ...obj,
                                                  oldInvoiceContenet: obj.business,
                                                });
                                              }
                                              "
                      />
                    </a-space>
                  </div>

                  <div style="background-color: #fff; margin-top: 8px">
                    <a-table bordered :data-source="selectedInvoiceList" :pagination="false">
                      <a-table-column title="原发票号码" data-index="invoiceCode" />
                      <a-table-column title="原发票金额(元)">
                                     <template slot-scope="text">
                                      {{ text.totalPriceLowercase}}
                        </template>
                        </a-table-column>
                      <a-table-column title="原开票单位" data-index="salesUnitName" />
                      <a-table-column title="原开票内容" data-index="oldInvoiceContenet" />


                      <a-table-column title="退票类型" v-if="form.getFieldValue('applyType') === 'refund'">
                       <template slot-scope="text, row, index">
                                      <a-select
                                          style="width: 100%"
                                          placeholder='请选择'
                                          :value="text.refundType"
                                          @change="value => {  selectedInvoiceList.splice(index,1, {
                                            ...text,
                                            refundType: value,
                                          }) }"
                                          size='small'
                                        >
                                          <a-select-option
                                            v-for="item in [{
                                              value: '全额退票',
                                              name: '全额退票',
                                            },{
                                              value: '部分退票',
                                              name:'部分退票',
                                            }]"
                                            :key="item.value"
                                            :value="item.value"
                                          >{{ item.name }}</a-select-option>
                                        </a-select>
                          </template>
                        </a-table-column>

                        <a-table-column title="退票金额(元)" v-if="form.getFieldValue('applyType') === 'refund'">
                         <template slot-scope="text, row, index">
                            <div>
                                <span v-if="text.refundType === '部分退票'">
                                    <a-input-number
                                          size='small'
                                          placeholder='请输入'
                                          :value='text.refundPrice' 
                                          :max='parseFloat(text.totalPriceLowercase)'
                                          @change="value => {
                                            selectedInvoiceList.splice(index,1, {
                                            ...text,
                                            refundPrice: value,
                                          })
                                          }"
                                           />
                                 </span>
                                 <span v-else-if="text.refundType === '全额退票'">
                                    {{ text.totalPriceLowercase }}
                                 </span>
                            </div>
                          </template>
                        </a-table-column>

                      <a-table-column title="操作" align="center">
                        <template slot-scope="text, row, index">
                          <a href="#" class="danger" @click="removeInvoice(index)">删除</a>
                        </template>
                      </a-table-column>
                    </a-table>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </template>

        <template v-if="form.getFieldValue('applyType') !== 'refund'">
          <div class="title">购方信息</div>

          <div class="wrapper">
            <a-row>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="单位名称">
                  <CompanySelector @change="onSelectCompany">
                    <a-button
                      block
                      style="text-align: left"
                      class="ellipsis"
                    >{{ selectedCompany.name }}</a-button>
                  </CompanySelector>
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="客商编码">
                  <a-input
                    v-decorator="[
                                        'companyCode',
                                        { rules: [{ required: true, message: '请输入！' }] },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="发票类别">
                  <a-radio-group
                    v-decorator="[
                                        'invoiceType',
                                        { rules: [{ required: true, message: '请选择！' }] },
                                      ]"
                    button-style="solid"
                  >
                    <a-radio-button
                      v-for="item in invoiceTypeList"
                      :key="item.value"
                      :value="item.value"
                    >{{ item.name }}</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="开票内容">
                  <a-input
                    v-decorator="[
                                        'invoiceContent',
                                        { rules: [{ required: true, message: '请输入！' }] },
                                      ]"
                  />
                </a-form-item>
              </a-col>

              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="开票金额（元）">
                  <a-input-number
                    :formatter="$inputNumberFormat"
                    style="width: 100%"
                    :min="0"
                    v-decorator="[
                                        'invoiceAmount',
                                        { rules: [{ required: true, message: '请输入！' }] },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="纳税人识别号">
                  <a-input
                    v-decorator="[
                                        'taxpayerIdNumber',
                                        {
                                          rules: [
                                            {
                                              required:
                                                form.getFieldValue('invoiceType') === 'special',
                                              validator: validatorTaxNumber,
                                            },
                                          ],
                                        },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="开户行">
                  <a-input
                    v-decorator="[
                                        'bank',
                                        {
                                          rules: [
                                            {
                                              required:
                                                form.getFieldValue('invoiceType') === 'special',
                                              message: '请输入！',
                                            },
                                          ],
                                        },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="银行账号">
                  <a-input
                    v-decorator="[
                                        'account',
                                        {
                                          rules: [
                                            {
                                              required:
                                                form.getFieldValue('invoiceType') === 'special',
                                              message: '请输入！',
                                            },
                                          ],
                                        },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="联系地址">
                  <a-input
                    v-decorator="[
                                        'address',
                                        {
                                          rules: [
                                            {
                                              required:
                                                form.getFieldValue('invoiceType') === 'special',
                                              message: '请输入！',
                                            },
                                          ],
                                        },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="联系电话">
                  <a-input
                    v-decorator="[
                                        'telephone',
                                        {
                                          rules: [
                                            {
                                              required:
                                                form.getFieldValue('invoiceType') === 'special',
                                              message: '请输入！',
                                            },
                                          ],
                                        },
                                      ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :lg="24" :md="24" :sm="24">
                <a-form-item label="发票备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                  <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['invoiceRemark']" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </template>

        <div class="title">项目信息</div>
        <div class="wrapper">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="项目名称">
                <ContractSelector @change="onSelectProject">
                  <a-button
                    block
                    style="text-align: left"
                    class="ellipsis"
                  >{{ selectedProject.name }}</a-button>
                </ContractSelector>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="设计编号">
                <a-button block style="text-align: left" class="ellipsis">{{ selectedProject.code }}</a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="是否总院项目">
                <a-radio-group :disabled="true" v-decorator="['productHead']" button-style="solid">
                  <a-radio
                    v-for="item in booleanList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同性质">
                <a-button block style="text-align: left">
                  <DataDictFinder
                    v-if="selectedProject"
                    dictType="contractType"
                    :dictValue="selectedProject.type"
                  />
                </a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="项目归属">
                <a-button block style="text-align: left">
                  {{
                  selectedProject
                  ? selectedProject.productionUnitNameUnique
                  : ""
                  }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="所属区域指挥部">
                <a-button
                  block
                  style="text-align: left"
                >{{ selectedProject ? selectedProject.areaName : "" }}</a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合作单位">
                <a-input v-decorator="['partner']" />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="发包人">
                <a-button
                  block
                  style="text-align: left"
                >{{ selectedProject ? selectedProject.partyAname : "" }}</a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="合同总价（元）">
                <a-button block style="text-align: left">{{ selectedProject.contractAmount }}</a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="已开发票（元）">
                <a-button block style="text-align: left">
                  {{
                  selectedProject.code === "0000000000"
                  ? "--"
                  : hasInvoiceAmount
                  }}
                </a-button>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="是否集团项目">
                <a-radio-group v-decorator="['isGroupProject']" button-style="solid">
                  <a-radio
                    v-for="item in booleanList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col
              :lg="12"
              :md="12"
              :sm="24"
              v-if="String(form.getFieldValue('isGroupProject')) === '1'"
            >
              <a-form-item label="集团名称">
                <a-input
                  v-decorator="[
                                    'groupName',
                                    {
                                      rules: [{ required: true, message: '请输入！' }],
                                    },
                                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item label="合同付款条款" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <div style="background-color: #fff">
                  <a-table
                    bordered
                    :dataSource="planReceiptList"
                    :pagination="false"
                    rowKey="id"
                    :row-selection="{
                                        selectedRowKeys,
                                        onChange: onSelectChange,
                                      }"
                  >
                    <a-table-column title="编号" align="center">
                      <template slot-scope="text">
                        <span>{{ text.code }}</span>
                      </template>
                    </a-table-column>
                    <a-table-column title="收款项名称">
                      <template slot-scope="text">
                        <span>{{ text.name }}</span>
                      </template>
                    </a-table-column>
                    <a-table-column title="收款比例" align="right">
                      <template slot-scope="text">
                        <span>{{ text.ratio }}%</span>
                      </template>
                    </a-table-column>
                    <a-table-column title="收款金额(万元)" align="right">
                      <template slot-scope="text">
                        <Money :money="parseFloat(text.planReceipt)" />
                      </template>
                    </a-table-column>

                    <a-table-column title="进度确认" align="center">
                      <template slot-scope="text">
                        <a-icon type="check" v-if="text.isFinished" />
                      </template>
                    </a-table-column>
                  </a-table>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="项目进展情况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['prjProgressDesc']" />
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <div class="title">申请材料</div>
        <div class="wrapper">
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="上传">
                <FileUpload @uploaded="uploaded">
                  <a-button type="primary">立即上传</a-button>
                </FileUpload>
              </a-form-item>
            </a-col>

            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="附件" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <div style="background-color: #fff">
                  <a-table bordered :dataSource="fileList" :pagination="false">
                    <a-table-column title="名称">
                      <template slot-scope="text">
                        <a :href="text.completePath" target="_blank">
                          {{
                          $getFileName(text.completePath)
                          }}
                        </a>
                      </template>
                    </a-table-column>

                    <a-table-column title="操作" align="center">
                      <template slot-scope="text, record, index">
                        <a href="#" @click.prevent="deleteFile(index)" class="danger">删除</a>
                      </template>
                    </a-table-column>
                  </a-table>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </div>

        <div class="center">
          <a-space>
            <a-button @click="addDraft">存草稿</a-button>
            <a-button
              type="primary"
              html-type="submit"
              :loading="loading"
            >{{ resubmit ? "重新发起审批" : "发起审批" }}</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>

    <CommentModal
      :visible="commentVisible"
      :title="commentTitle"
      @setVisible="setCommentVisible"
      @done="done"
    />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import draft from "@/mixins/draft";
import { fetchProjectInvoiceAmount, apply } from "@/api/invoice";
import { fetchDetail } from "@/api/contract";
import { fetchDetail as fetchDeptDetail } from "@/api/setting/organization";

import { mapGetters, mapState, mapMutations, mapActions } from "vuex";

import AddOriginalInvoice from "./components/AddOriginalInvoice";
import InvoiceSelector from "@/components/invoice-selector";
import EmployeeSelector from "@/components/employee-selector";
import ContractSelector from "@/components/contract-selector";
import CompanySelector from "@/components/company-selector";
import CommentModal from "@/components/comment-modal";
import FileUpload from "@/components/file-upload";
import moment from "moment";

export default {
  name: "applyInvoice",

  mixins: [area, organization, watermark, draft],

  components: {
    EmployeeSelector,
    AddOriginalInvoice,
    ContractSelector,
    CompanySelector,
    InvoiceSelector,
    CommentModal,
    FileUpload
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,

      selectedEmployee: {}, // 生产负责人
      selectedAreaMaster: {}, // 区域负责人

      selectedInvoiceList: [], // 选择的发票，准备退票或换票

      selectedProject: {}, // 选中的项目
      hasInvoiceAmount: 0, // 已开发票

      selectedCompany: {}, // 选择的公司

      commentVisible: false, // 审批意见弹窗
      commentTitle: "", // 标题
      storageValue: {}, // 暂存的数据

      planReceiptList: [], // 合同收款项列表
      selectedRowKeys: [], // 选择的收款项

      resubmit: false, // 是否是重新提交

      fileList: []
    };
  },

  computed: {
    ...mapState("auth", ["user"]), // 用户信息
    ...mapGetters("auth", ["department"]), // 用户的部门
    ...mapGetters("setting", ["findDataDict"]),

    // 开票/换票/退票类型
    applyTypeList() {
      return this.findDataDict("applyType");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
    // 合同性质
    projectPropertyList() {
      return this.findDataDict("prjProperty");
    },

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },

    // 发票类别
    invoiceTypeList() {
      return this.findDataDict("invoiceType");
    },

    ...mapState("approval", ["detail", "taskId", "instanceId"])
  },

  activated() {
    const { query } = this.$route;
    const { taskId, instanceId } = query || {};
    if (taskId && instanceId) {
      this.resubmit = true; // 是否是重新提交

      // 重新提交进入开票页面，需要将数据填充进去
      this.setTaskId(taskId);
      this.setInstanceId(instanceId);
      this.getDetail().then(() => {
        // 将数据填充进表单
        if (this.detail.extra) {
          const {
            liquidator,
            liquidatorName,
            liquidatorDept,

            areaMasterId,
            areaMasterName,

            areaId,
            subAreaId,

            applyType,
            buyerInfo = {}, // 购方信息
            refundChangeInfo = {}, // 退换票信息
            projectInfo = {}, // 项目信息
            materials
          } = this.detail.extra;

          // 生产负责人
          this.selectedEmployee = {
            userId: liquidator,
            name: liquidatorName
          };

          this.selectedAreaMaster = {
            userId: areaMasterId,
            name: areaMasterName
          };

          this.selectedCompany = {
            name: buyerInfo.name,
            id: buyerInfo.id
          };

          // 退换票信息
          if (Array.isArray(refundChangeInfo.oldInvoices)) {
            this.selectedInvoiceList = refundChangeInfo.oldInvoices.map(
              item => ({
                invoiceCode: item.oldInvoiceNo,
                totalPriceLowercase: item.oldInvoicePrice,
                salesUnitName: item.oldInvoiceBuyer,
                oldInvoiceContenet: item.oldInvoiceContenet,
                refundType: item.refundType,
                refundPrice: item.refundPrice,
              })
            );
          }

          this.fileList =
            typeof materials === "string"
              ? materials.split(",").map(item => {
                  return {
                    completePath: item
                  };
                })
              : [];

          // 项目信息
          this.selectedProject = {
            name: projectInfo.name,
            id: projectInfo.id,
            code: projectInfo.designCode,
            type: projectInfo.projectType,
            productionUnitId: projectInfo.productionUnitId, // 项目归属ID
            productionUnitNameUnique: projectInfo.productionUnitName,
            areaId: projectInfo.areaId, // 项目区域ID
            areaName: projectInfo.areaName,
            partyAname: projectInfo.partyAname, // 发包人
            contractAmount: projectInfo.projectAmount // // 合同总价(元)
          };

          this.hasInvoiceAmount = projectInfo.invoiceAmount; // 已开发票

          this.getContractExtraData(projectInfo.id);

          if (projectInfo.planReceipt && projectInfo.planReceipt.id) {
            this.onSelectChange([projectInfo.planReceipt.id]);
          }
          if (
            Array.isArray(projectInfo.planReceipts) &&
            projectInfo.planReceipts.length > 0
          ) {
            this.onSelectChange(
              projectInfo.planReceipts
                .filter(item => !!item)
                .map(item => item.id)
            );
          }

          let area = [];
          if (areaId && subAreaId) {
            area = [areaId, subAreaId];
          }

          this.form.setFieldsValue({
            liquidatorDept, // 清收责任部门

            area,

            applyType,

            // 退换票信息
            reason: refundChangeInfo.reason,

            // 购方信息
            companyCode: buyerInfo.code, // 客商编码
            taxpayerIdNumber: buyerInfo.taxpayerIdNumber, // 纳税人识别号
            bank: buyerInfo.bank, // 开户行
            account: buyerInfo.account, // 银行账号
            address: buyerInfo.address, // 联系地址
            telephone: buyerInfo.telephone, // 联系电话
            invoiceType: buyerInfo.invoiceType,
            invoiceAmount: buyerInfo.invoiceAmount, // 开票金额（元）
            invoiceContent: buyerInfo.invoiceContent, // 开票内容
            invoiceRemark: buyerInfo.invoiceRemark,

            productHead:
              typeof projectInfo.productHead === "number"
                ? String(projectInfo.productHead)
                : undefined, // 是否总院项目
            // projectType: projectInfo.projectType, // 合同性质
            partner: projectInfo.partner, // 合作单位
            isGroupProject:
              typeof projectInfo.isGroupProject === "number"
                ? String(projectInfo.isGroupProject)
                : undefined, // 是否集团项目：0-否，1-是
            groupName: projectInfo.groupName, // 集团名称
            prjProgressDesc: projectInfo.prjProgressDesc // 项目进展情况
          });

          setTimeout(() => {
            // 等待刷新后设置数据
            this.form.setFieldsValue({
              groupName: projectInfo.groupName // 集团名称
            });
          }, 0);
        }
      });
    }
  },

  methods: {
    onDeptChange(id) {
      this.form.resetFields(["productHead"]);

      fetchDeptDetail({
        uuid: id
      }).then(res => {
        console.log("dept res", res);
        if (res && res.type) {
          if (
            res.type === "product" ||
            res.type === "direct_branch" ||
            res.type === "function"
          ) {
            this.form.setFieldsValue({
              productHead: "1"
            });
          } else {
            this.form.setFieldsValue({
              productHead: "0"
            });
          }
        }
      });
    },

    removeInvoice(index) {
      this.selectedInvoiceList.splice(index, 1);
    },

    ...mapMutations("approval", ["setTaskId", "setInstanceId"]),
    ...mapActions("approval", ["getDetail"]),

    // 监听选中合同
    onSelectProject(contract) {
      this.getContractExtraData(contract.id);
      this.selectedRowKeys = []; // 重置已选择
    },

    getContractExtraData(id) {
      // 获取该合同关联的发票数据
      fetchProjectInvoiceAmount({ id })
        .then(res => {
          if (res && res.amount) {
            this.hasInvoiceAmount = res.amount; // 已开发票
          } else {
            this.hasInvoiceAmount = 0;
          }
        })
        .catch(() => {
          this.hasInvoiceAmount = 0;
        });

      fetchDetail(id)
        .then(res => {
          if (res) {
            this.selectedProject = res;
          }

          if (res && Array.isArray(res.planReceiptList)) {
            this.planReceiptList = res.planReceiptList;
          } else {
            this.planReceiptList = [];
          }
        })
        .catch(() => {
          this.selectedProject = {};
          this.planReceiptList = [];
        });
    },

    // 选择合同付款条款
    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },

    // 监听选中一家公司
    onSelectCompany(company) {
      this.selectedCompany = company;

      this.form.setFieldsValue({
        companyCode: company.code,
        address: company.address,
        telephone: company.telephone
      });
      if (Array.isArray(company.banks) && company.banks.length > 0) {
        const bank = company.banks[0];
        this.form.setFieldsValue({
          bank: bank.headBank,
          account: bank.account
        });
      } else {
        this.form.setFieldsValue({
          bank: "",
          account: ""
        });
      }
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
    },

    // 校验纳税人识别号
    validatorTaxNumber(rule, value) {
      const flag = this.form.getFieldValue("invoiceType") === "special";

      return new Promise((resolve, reject) => {
        if (flag) {
          if (!value) {
            reject("请输入！");
          } else if (!/^[0-9a-zA-Z]{18}$/.test(value)) {
            reject("纳税人识别号不正确！");
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },

    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile(index) {
      this.fileList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.selectedEmployee.userId) {
            this.$message.error("请选择生产负责人！");
            return;
          }

          if (values.applyType === "change" || values.applyType === "refund") {
            if (this.selectedInvoiceList.length === 0) {
              this.$message.error("请上传需要退换的发票！");
              return;
            }

            if (values.applyType === 'refund') {
              let flag = '';

              for(let i = 0; i < this.selectedInvoiceList.length; i++) {
                const item = this.selectedInvoiceList[i];
                if (!item.refundType) {
                  flag = 'refundType';
                  break;
                }
                if (item.refundType === '部分退票' &&  typeof item.refundPrice !== 'number') {
                  flag = 'refundPrice'
                  break;
                } 
              }
    
                  if (flag) {
                    if (flag === 'refundType') {
                      this.$message.error("请选择退票类型");
                      return;
                    }
                    if (flag === 'refundPrice') {
                      this.$message.error("请填写退票金额");
                      return;
                    }
               
                  }
            }
          }

          if (!this.selectedProject.id) {
            this.$message.error("请选择项目！");
            return;
          }

          if (Array.isArray(values.area) && values.area.length === 1) {
            this.$message.error("请选择所属区域！");
            return;
          }

          // 开票和换票
          if (values.applyType !== "refund") {
            // 如果有收款项，校验必选
            if (this.planReceiptList.length > 0) {
              if (!this.selectedRowKeys.length) {
                this.$message.error("请选择合同付款条款！");
                return;
              } else {
                // 已开票金额-本次换票的原发票金额+本次开票金额>合同金额时   为超额开票
                let oldAmount = 0;
                if (values.applyType === "change") {
                  this.selectedInvoiceList.forEach(item => {
                    oldAmount += parseFloat(item.totalPriceLowercase);
                  });
                }

                console.log("invoiceAmount", values.invoiceAmount);
                console.log("hasInvoiceAmount", this.hasInvoiceAmount);
                console.log("oldAmount", oldAmount);
                console.log(
                  "contractAmount",
                  this.selectedProject.contractAmount
                );
                const diff =
                  parseFloat(values.invoiceAmount) +
                  parseFloat(this.hasInvoiceAmount) -
                  parseFloat(oldAmount) -
                  parseFloat(this.selectedProject.contractAmount);

                if (diff > 0) {
                  const that = this;
                  this.$confirm({
                    title: `您已超额开票${diff}元，是否继续提交？`,
                    onOk() {
                      that.continueSubmit(values, diff);
                    }
                  });
                  return;
                } else {
                  this.continueSubmit(values);
                }
              }
            } else {
              this.continueSubmit(values);
            }
          } else {
            this.continueSubmit(values);
          }
        }
      });
    },

    continueSubmit(values, overPlanReceipt = 0) {
      this.commentVisible = true;
      this.commentTitle = this.messageList.find(
        item => item.value === "editContract"
      ).name;

      let params = {};

      if (values.applyType === "change" || values.applyType === "refund") {
        params = {
          refundChangeInfo: {
            reason: values.reason,
            oldInvoices: this.selectedInvoiceList.map(item => {
              return {
                oldInvoiceNo: item.invoiceCode,
                oldInvoicePrice: item.totalPriceLowercase,
                oldInvoiceBuyer: item.salesUnitName,
                oldInvoiceContenet: item.oldInvoiceContenet,
                refundType: item.refundType,
                refundPrice: item.refundType === '全额退票' ?  parseFloat(item.totalPriceLowercase) : item.refundPrice,
              };
            })
          }
        };
      }

      if (values.applyType !== "refund") {
        params = {
          ...params,
          // 购方信息
          buyerInfo: {
            name: this.selectedCompany.name,
            id: this.selectedCompany.id,
            code: values.companyCode, // 客商编码
            taxpayerIdNumber: values.taxpayerIdNumber, // 纳税人识别号
            bank: values.bank, // 开户行
            account: values.account, // 银行账号
            address: values.address, // 联系地址
            telephone: values.telephone, // 联系电话

            invoiceType: values.invoiceType,
            invoiceAmount: values.invoiceAmount, // 开票金额（元）
            overPlanReceipt, // 是否超过了该收款项的历史总金额，不超默认为0
            invoiceContent: values.invoiceContent, // 开票内容
            invoiceRemark: values.invoiceRemark
          }
        };
      }

      let planReceipts = this.planReceiptList.filter(item => {
        const obj = this.selectedRowKeys.find(key => item.id === key);
        return !!obj;
      });

      let areaId, areaName, subAreaId, subAreaName;

      if (Array.isArray(values.area) && values.area.length >= 2) {
        areaId = values.area[0];
        subAreaId = values.area[1];

        const area1 = this.rawAreaList.find(item => item.id === areaId);
        const area2 = this.rawAreaList.find(item => item.id === subAreaId);

        areaName = area1.areaName;
        subAreaName = area2.areaName;
      }

      this.storageValue = {
        taskType: "fin_invoice", // 审批任务类型
        extra: JSON.stringify({
          // 申请部门、申请人
          applicantDeptID: this.department.deptId,
          applicantDeptName: this.department.deptUniqueName,
          applicantId: this.user.uuid,
          applicantName: this.user.userName,

          // 清收责任部门、生产负责人
          liquidator: this.selectedEmployee.userId,
          liquidatorName: this.selectedEmployee.name,
          liquidatorDept: values.liquidatorDept,
          liquidatorDeptName: this.organizationList.find(
            item => item.id === values.liquidatorDept
          ).uniqueName,

          areaMasterId: this.selectedAreaMaster.userId,
          areaMasterName: this.selectedAreaMaster.name,

          // areaId: values.areaId,
          // areaName: values.areaId
          //   ? this.areaList.find((item) => item.id === values.areaId).areaName
          //   : undefined,

          areaId,
          areaName,
          subAreaId,
          subAreaName,

          // 开票换票类型
          applyType: values.applyType,

          ...params,

          // 项目信息
          projectInfo: {
            name: this.selectedProject.name, // 项目名称
            id: this.selectedProject.id,
            designCode: this.selectedProject.code, // 设计编号

            productHead: values.productHead, // 是否总院项目
            projectType: this.selectedProject.type, // 合同性质

            productionUnitId: this.selectedProject.productionUnitId, // 项目归属ID
            productionUnitName: this.selectedProject.productionUnitNameUnique, // 项目归属

            areaId: this.selectedProject.areaId, // 项目区域ID
            areaName: this.selectedProject.areaName, // 项目区域

            partner: values.partner, // 合作单位
            partyAname: this.selectedProject.partyAname, // 发包人
            projectAmount: this.selectedProject.contractAmount, // 合同总价(元)
            invoiceAmount: this.hasInvoiceAmount, // 已开发票

            isGroupProject: values.isGroupProject, // 是否集团项目：0-否，1-是
            groupName: values.groupName, // 集团名称
            prjProgressDesc: values.prjProgressDesc, // 项目进展情况

            // 合同收款项
            planReceipts
          },

          materials: this.fileList.map(item => item.completePath).join(",")
        })
      };
    },

    done(comment) {
      this.loading = true;

      let extra = {};
      if (this.resubmit) {
        extra.taskId = this.taskId;
        extra.processInstanceId = this.instanceId;
      }

      apply({
        ...this.storageValue,
        comment,
        ...extra
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .catch(err => {
          console.log("err", err);
          if (err.message) {
            this.$error({
              title: "提示",
              content: err.message
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveDraft() {
      this.updateDraft({
        id: this.draftId ? this.draftId : undefined,
        title: this.draftTitle + " " + moment().format("YYYY-MM-DD"),
        type: this.draftType,
        content: JSON.stringify({
          values: this.form.getFieldsValue(),

          selectedInvoiceList: this.selectedInvoiceList,
          selectedCompany: this.selectedCompany,
          planReceiptList: this.planReceiptList,
          selectedEmployee: this.selectedEmployee,
          selectedAreaMaster: this.selectedAreaMaster,
          selectedProject: this.selectedProject,
          hasInvoiceAmount: this.hasInvoiceAmount,
          fileList: this.fileList
        })
      }).then(() => {
        this.draftVisible = false;
        this.draftTitle = "";
      });
    },

    onSelectDraft(draft) {
      if (draft.id && draft.content) {
        this.draftId = draft.id; // 暂存使用的草稿 id
        this.draftTitle =
          typeof draft.title === "string" ? draft.title.split(" ")[0] : "";

        const res = JSON.parse(draft.content);

        if (res.values.groupName) {
          this.form.setFieldsValue({
            ...res.values,
            groupName: undefined
          });

          this.form.getFieldDecorator("groupName", {
            initialValue: res.values.groupName
          });
        } else {
          this.form.setFieldsValue({
            ...res.values
          });
        }

        this.selectedInvoiceList = res.selectedInvoiceList;
        this.selectedCompany = res.selectedCompany;
        this.planReceiptList = res.planReceiptList;
        this.selectedEmployee = res.selectedEmployee;
        this.selectedAreaMaster = res.selectedAreaMaster;
        this.selectedProject = res.selectedProject;
        this.hasInvoiceAmount = res.hasInvoiceAmount;
        this.fileList = res.fileList;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.wrapper {
  background-color: #f9f9f9;
  padding-top: 24px;
  padding-bottom: 8px;
}

.title {
  font-weight: bold;
  font-size: 17px;
  margin: 12px 0;
}

.center {
  margin-top: 80px;
}
</style>
