import Draft from "@/components/draft";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Draft,
  },
  data() {
    return {
      draftVisible: false,
      draftTitle: "",
      draftId: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["getCurrentRoute"]),

    draftType() {
      let type = "";
      const routeObj = this.getCurrentRoute(this.$route.path);
      if (routeObj) {
        type = routeObj.name;
      }
      return type;
    },
  },

  methods: {
    addDraft() {
      this.draftVisible = true;
    },
    cancelDraft() {
      this.draftVisible = false;
    },
    ...mapActions("draft", ["updateDraft"]),
  },
};
